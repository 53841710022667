<template>
  <div class="@container">
    <div v-show="loading" aria-hidden="true" :class="gridClasses">
      <course-card-placeholder
        v-for="index in 8"
        :key="index"
        skeleton-class="bg-primary-100"
      />
    </div>

    <transition-group
      :class="gridClasses"
      enter-active-class="transition"
      enter-from-class="opacity-0 translate-y-1"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-1"
      move-class="transition"
      tag="ul"
    >
      <li v-for="course in list" :key="course.slug">
        <course-card
          class="h-full"
          :course="course"
          @click="onCardClick(course)"
        />
      </li>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import type { TrackingPlanModel } from "~/business-areas/analytics/analytics.model";
import { toAnalyticsCourseView } from "~/business-areas/analytics/analytics.model";
import type { CourseCardView } from "~/business-areas/course/composables/course-card-list.hook";

const properties = defineProps<{
  list: CourseCardView[];
  id: TrackingPlanModel["course_clicked"]["origin"];
  loading?: boolean;
}>();

const { $analytics } = useNuxtApp();
const onCardClick = (course: CourseCardView) =>
  $analytics.track("course_clicked", {
    origin: properties.id,
    course: toAnalyticsCourseView(course),
  });

const gridClasses =
  "grid place-content-center gap-6 gap-y-12 @lg:grid-cols-2 @3xl:grid-cols-3 @6xl:grid-cols-4";
</script>
