<template>
  <div>
    <search-filters v-model="filters" :total-number-of-results="data?.length" />

    <div
      class="relative grid gap-sections bg-gradient-to-b from-primary-100 via-transparent via-[50vh]"
    >
      <search-page-no-results v-show="isEmpty" />

      <app-page-wrapper
        v-show="!isEmpty"
        class="relative px-12 pt-16"
        tag="section"
      >
        <div class="mx-auto mb-8">
          <app-skeleton-text
            v-if="status === 'pending' && data.length === 0"
            class="mx-auto bg-primary-200"
            :width="200"
          />
          <h1 v-else class="balance text-center text-xl" v-html="title" />
        </div>

        <course-card-grid
          v-if="data"
          id="search"
          :list="data"
          :loading="status === 'pending' && data.length === 0"
          origin="search"
        />
      </app-page-wrapper>

      <app-page-wrapper class="grid gap-8 md:grid-cols-2 lg:gap-16">
        <app-page-action-banner
          class="bg-primary-900 text-white"
          :subtitle="$t('search.page.signup_banner.subtitle')"
          :title="$t('search.page.signup_banner.title')"
        >
          <template #cta>
            <growth-contact-widget origin="search_page">
              <app-button color="light" size="lg">{{
                $t("search.page.signup_banner.cta_label")
              }}</app-button>
            </growth-contact-widget>
          </template>
        </app-page-action-banner>

        <app-page-action-banner
          class="bg-deco2"
          :subtitle="$t('search.page.submit_topic.subtitle')"
          :title="$t('search.page.submit_topic.title')"
        >
          <template #cta>
            <app-button
              color="dark"
              :href="topic_proposal"
              size="lg"
              variant="outlined"
              @click="$analytics.track('search_topic_proposal_form_clicked')"
            >
              {{ $t("search.page.submit_topic.cta") }}
            </app-button>
          </template>
        </app-page-action-banner>
      </app-page-wrapper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchFilters from "~/pages/search/_includes/components/search-filters.vue";
import SearchPageNoResults from "~/pages/search/_includes/components/search-page-no-results.vue";
import { useSearchPageFiltersData } from "~/pages/search/_includes/composables/search-page-filters.data";
import { useSearchPageTitle } from "~/pages/search/_includes/composables/search-page-title.hook";

useSeoMeta({
  robots: "noindex",
});

const { filters } = useSearchPageFiltersData();
const { data: tags } = useTagList({ depth: 0, used: true });
const { data, status } = await useCourseCardList("search_page", filters, {
  lazy: true,
});

const isEmpty = computed(
  () => status.value === "success" && data.value.length === 0,
);

const title = useSearchPageTitle({
  results: data,
  selectedTags: computed(
    () => tags.value?.filter((tag) => filters.tags?.includes(tag.slug)) ?? [],
  ),
});

const {
  public: {
    typeforms: { topic_proposal },
  },
} = useRuntimeConfig();

const { $analytics } = useNuxtApp();

watch(filters, () => {
  document.documentElement.scrollTop = 0;
});
</script>
